// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import React, { HTMLAttributeAnchorTarget } from 'react';
import Link from 'next/link';

interface LinkWithAnchorProps {
  href: string | UrlObject;
  as?: string;
  target?: HTMLAttributeAnchorTarget;
  children?: any;
}

const LinkWithAnchor: React.FC<LinkWithAnchorProps> = ({
  href,
  as,
  target,
  children,
}) => {
  return (
    <Link href={href} as={as}>
      <a target={target}>{children}</a>
    </Link>
  );
};

export default LinkWithAnchor;
