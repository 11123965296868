import React from 'react';
import { OfficesResponse } from '../openapi/bff';
import { LegaleseElement } from '../openapi/wanderer';
import {
  TypeFooter,
  TypeMarketsCountrySection,
} from '../types/generated/page-contents';
import FooterDisclaimerRedesigned from './FooterDisclaimerRedesigned';
import FooterRedesigned from './FooterRedesigned';
import FooterTop from './FooterTop';
import Nav from './Nav';

interface LayoutRedesignedProps {
  children: any;
  countriesWithStates: TypeMarketsCountrySection[];
  disclaimers?: LegaleseElement[];
  footerContent: TypeFooter;
  allOffices: OfficesResponse;
}

const LayoutRedesigned: React.FC<LayoutRedesignedProps> = ({
  children,
  countriesWithStates,
  disclaimers,
  footerContent,
  allOffices,
}) => {
  return (
    <div>
      <Nav />
      {children}
      <div className='bg-footer-bg py-10 md:py-20 md:px-28 md:mt-36 mt-10'>
        <FooterTop />
        <FooterRedesigned footerContent={footerContent} />
        <FooterDisclaimerRedesigned
          disclaimers={disclaimers}
          brokerLicenses={[]}
          countriesWithStates={countriesWithStates}
          allOffices={allOffices}
        />
      </div>
    </div>
  );
};

export default LayoutRedesigned;
