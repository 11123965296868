import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';
import env from '../utils/env';
import { isCASiteOnly } from '../utils/SiteConfiguration';

interface IOption {
  title: string;
  desc: string;
  onClick?(): void;
  link?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

export type NavDropdownOptionsType =
  | 'Brokerage'
  | 'Mortgage'
  | 'Title'
  | 'About';

interface NavDropdownOptionsProps {
  type: NavDropdownOptionsType;
}

const contactUsOption: IOption = {
  title: 'Contact Us',
  desc: "We're here to help. 24/7.",
  link: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/contact`,
};

const isCASite = isCASiteOnly();

export const brokerageOptions: IOption[] = [
  {
    title: 'Property Search',
    desc: 'Explore exclusive listings, and discover your new home.',
    link: '/listings',
  },
  // {
  //   title: 'Rent',
  //   desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //   onClick() {
  //     handleClick('rent');
  //   },
  // },
  {
    title: 'Sell Your Home',
    desc: 'Go from selling to sold quickly. Hassle-free.',
    link: '/pages/sell',
  },
  {
    title: 'Join Real',
    desc: 'Where you want to go. Join our Real agent family.',
    link: isCASite
      ? `${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}/pages/join-real`
      : `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/join-real`,
  },
  {
    title: 'Find an Agent',
    desc: 'Connect with the best and find your agent partner.',
    link: '/search-agent',
  },
];

export const mortgageOptions: IOption[] = [
  {
    title: 'One Real Mortgage',
    desc: 'Seamless access to custom loan options for you.',
    link: env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN,
  },
  {
    title: 'Purchase or Refinance',
    desc: 'Eliminate uncertainty with our 14-day clear to close guarantee.',
    link: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/purchase`,
  },
  {
    title: 'Current Programs',
    desc: 'Exclusive promotions to save you time and money.',
    link: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/programs`,
  },
  {
    ...contactUsOption,
    link: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/contact-us`,
  },
];

export const titleOptions: IOption[] = [
  {
    title: 'One Real Title',
    desc: 'Seamless, tech-driven closings you can count on.',
    link: env.NEXT_PUBLIC_TITLE_SUBDOMAIN,
  },
  {
    title: 'One Real Escrow (California)',
    desc: 'Simplify your transaction with transparency every step of the way.',
    link: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/escrow`,
  },
  contactUsOption,
];

export const aboutOptions: IOption[] = [
  {
    title: 'Real Newsroom',
    desc: 'A deeper dive into the fastest-growing publicly traded real estate brokerage.',
    link: env.NEXT_PUBLIC_NEWSROOM_SUBDOMAIN,
    target: '_blank',
  },
  {
    title: 'Leadership Team',
    desc: 'Agent-focused innovators committed to building the brokerage of the suture.',
    link: '/pages/leadership',
  },
  {
    title: isCASite ? 'Events' : 'Military Division',
    desc: isCASite
      ? 'Stay up to date and see where you can catch Real next.'
      : 'Find out more about how Real supports the military community.',
    link: isCASite
      ? env.NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN
      : `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
    target: '_target',
  },
  {
    ...contactUsOption,
    link: '/pages/contact-us',
  },
];

const NavDropdownOptions: React.FC<NavDropdownOptionsProps> = ({
  type = 'Brokerage',
}) => {
  // const handleClick = useRedirectToListings();

  const getOptions: () => IOption[] = useCallback(() => {
    switch (type) {
      case 'Brokerage':
        return brokerageOptions;
      case 'Mortgage':
        return mortgageOptions;
      case 'Title':
        return titleOptions;
      case 'About':
        return aboutOptions;
      default:
        return [];
    }
  }, [type]);

  const selectedDropdownOptions = useMemo(() => {
    return getOptions();
  }, [getOptions]);

  return (
    <div className='w-full border-t absolute z-50 -bottom-[185px] bg-white'>
      <div className='max-w-[1240px] mx-auto hidden md:grid md:grid-cols-4 py-4 gap-x-10'>
        {selectedDropdownOptions.map((option) => {
          const LinkContainer = option.link
            ? /https?:\/\//.test(option.link)
              ? 'a'
              : Link
            : 'div';

          const ChildWrapper = LinkContainer === Link ? 'a' : 'div';

          return (
            <LinkContainer
              key={option.title}
              onClick={option.onClick}
              href={option.link}
              target={option?.target || '_self'}
            >
              <ChildWrapper className='cursor-pointer hover:bg-[rgba(0,0,0,0.04)] p-4 rounded'>
                <p className='font-primary-bold'>{option.title}</p>
                <p className='font-primary-light text-gray-600 text-sm'>
                  {option.desc}
                </p>
              </ChildWrapper>
            </LinkContainer>
          );
        })}
      </div>
      <div className='bg-black p-4'>
        <p className='text-center text-white font-bold'>
          Proprietary Tech + End-to-End Services + Agent-Led Guidance +{' '}
          <span className='text-new-primary'>
            Everything you need under one roof
          </span>
        </p>
      </div>
    </div>
  );
};

export default NavDropdownOptions;
